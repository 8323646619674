import type { ConcreteComponent, ComputedOptions, MethodOptions } from 'vue'
export type TypeStep = {
  id: string
  component:
    | string
    | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions, {}, any>
  label?: string
  showEdit?: boolean
}
export type TypeCheckoutStepNavigation = {
  isActive: boolean
  ctaLabel: string
  showCta: boolean
}

const steps = ref<TypeStep[]>([])
const isActive = shallowRef<boolean>(false)
const showCta = ref<boolean>(true)
const currentStepIndex = ref<number>(0)
const currentStep = computed<TypeStep>(
  () => steps?.value[currentStepIndex.value]
)
const isThankYouPage = ref(false)

const setThankYouPage = (status: boolean) => (isThankYouPage.value = status)

export const useCheckoutStepNavigation = () => {
  /**
   * Event CTA click event
   *
   * Add CTA clicked event to component sample:
   * clickedBus.on((clickedStepId: string) => {
   *  if (clickedStepId === stepId) myFn()
   * })
   */
  const clickedBus = useEventBus<string>('checkout-step-cta-clicked')
  const clickedBusListener = (stepId: string) => {
    if (isActive.value && stepId !== 'CheckoutEmailValidation') {
      isActive.value = false
    }
  }
  clickedBus.on(clickedBusListener)

  const getStepIndexByStepId = (stepId: string) =>
    steps.value.findIndex(step => step.id === stepId)

  /**
   * Event Edit click event
   *
   * Add Edit clicked event to component sample:
   * clickedEditBus.on((data) => {
   * })
   */
  const clickedEditBus = useEventBus<{
    old: string
    new: string
  }>('checkout-step-edit-clicked')
  const goToStep = (stepId: string | undefined) => {
    if (stepId) {
      const stepIndex = getStepIndexByStepId(stepId)

      if (stepIndex !== -1) {
        clickedEditBus.emit({ old: currentStep.value.id, new: stepId })
        setEditVisibility(stepId, false)
        currentStepIndex.value = stepIndex
      }
    }
  }

  // Set CTA status (active or disabled)
  const setCtaStatus = (status: boolean) => (isActive.value = status)

  const setEditVisibility = (stepId: string | undefined, show: boolean) => {
    if (!stepId) return false
    const stepIndex = getStepIndexByStepId(stepId)
    steps.value[stepIndex].showEdit = show
  }

  // Go to next step
  const goToNextStep = () => {
    setEditVisibility(currentStep.value.id, true)
    currentStepIndex.value += 1
    isActive.value = false
  }

  const setSteps = (newSteps: TypeStep[]) => (steps.value = newSteps)
  const setShowCta = (show: boolean) => (showCta.value = show)

  const getEditVisibility = (stepId: string | undefined) => {
    if (!stepId) return false
    const stepIndex = getStepIndexByStepId(stepId)

    return stepIndex !== -1 ? steps.value[stepIndex].showEdit : false
  }

  const setCtaLabel = (stepId: string, label: string) => {
    if (!stepId) return false
    const stepIndex = getStepIndexByStepId(stepId)
    steps.value[stepIndex].label = label
  }

  // HANDLE ITEM STOCK ON NAVIGATION
  const { unavailableCartItemsIds } = useCartUtilities()
  const { refreshCart } = useCart()
  const showGoToCartSnackbar = useState<boolean>(
    'show-back-to-cart-checkout-snack',
    () => false
  )

  const triggerGoToCartSnackbar = () => {
    showGoToCartSnackbar.value = true
    if (import.meta.client) window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /* Stops checkout navigation and opens the side bar if any cart items is unavailable */
  const handleContinueButtonClick = async (
    currentStepId: string,
    isLogin?: boolean
  ) => {
    await refreshCart()
    if (unavailableCartItemsIds.value.length) {
      triggerGoToCartSnackbar()
    } else clickedBus.emit(currentStepId, isLogin)
  }

  return {
    isActive,
    clickedBus,
    clickedEditBus,
    setCtaStatus,
    goToNextStep,
    goToStep,
    setSteps,
    steps,
    currentStep,
    currentStepIndex,
    setShowCta,
    showCta,
    getStepIndexByStepId,
    getEditVisibility,
    setEditVisibility,
    isThankYouPage,
    setThankYouPage,
    setCtaLabel,
    showGoToCartSnackbar,
    handleContinueButtonClick,
  }
}
